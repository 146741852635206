const th = {
  translation: {
    login: "เข้าสุ่ระบบ",
    register: "ลงทะเบียนเลย",
    switchLanguage: "เปลี่ยนภาษา",
    home: "หน้าแรก",
    myProfile: "หน้าของฉัน",
    recharge: "เติมเงิน",
    withdraw: "ถอนเงิน",
    customerService: "ติดต่อฝ่ายบริการ",
    balance: "ยอดเงิน",
    ID: "ไอดี",
    shortTerm: "สมบัติระยะสั้น",
    small: "เล็ก",
    big: "ใหญ่",
    short: "สัน",
    long: "ยาว",
    high: "สูง",
    low: "ตำ",
    double: "คู่",
    single: "เดี่ยว",
    connectBankAccount: "ผูกบัญชีธนาคาร",
    bankAccount: "บัญชีธนาคาร",
    accountNo: "เลขบัญชีธนาคาร",
    accountName: "ชื่อบัญชี",
    recieverName: "ชื่อผู้รับเงิน",
    confirm: "ยืนยัน",
    rechartRecord: "บันทึกการเติมเงิน",
    withdrawRecord: "บันทึกการถอนเงิน",
    profitRecord: "บันทึกกำไร",
    memberLevel: "ระดับสมาชิก",
    totalAssets: "สินทรัพย์ทั้งหมด",
    onlineNumber: "หมายเลขออนไลน์",
    memberID: "รหัสสมาชิก",
    goldMember: "สมาชิกระดับโกลด์",
    vipMember: "สมาชิกระดับ VIP",
    receiptType: "ประเภทใบเสร็จ",
    bankName: "ธนาคารที่เปิดบัญชี",
    viewCardRecord: "ดูบันทึกการเติมเงิน",
    supportText: "ติดต่อฝ่ายบริการลูกค้าออนไลน์",
    withdrawAmount: "จำนวนเงินที่ถอน",
    bankCardNumber: "เลขบัญชีธนาคาร",
    payee: "ผู้รับเงิน",
    USDTAddress: "ที่อยู่ USDT",
    topupNotice:
      "กรุณาติดต่อฝ่ายบริการลูกค้าออนไลน์สำหรับบัญชีค่าใช้จ่ายล่าสุด！",
    topupBankNotice:
      "หมายเลขบัญชีเติมเงินด้วยบัตรธนาคาร：กรุณาติดต่อฝ่ายบริการลูกค้าออนไลน์สำหรับบัญชีค่าใช้จ่ายล่าสุด！",
    warmRemindertitle: "เตือนความจำที่อบอุ่น",
    warmRemindertext: "รับทราบการชำระเงินด้วยรหัส QR แล้วกรอกจำนวนเงินที่จะส่ง",
    remark: "หมายเหตุ",
    pleaseEnterTheRechargeAmount: "กรุณาใส่จำนวนเงินที่เติม",
    pleaseEnterTheWithdrawAmount: "กรุณากรอกจำนวนเงินที่ถอน",
    pleaseEnterANote: "กรุณาป้อนหมายเหตุ",
    rechargeAmount: "จำนวนเงินที่เติมเงิน",
    bankCardNotice:
      "ถูกผูกมัดแล้ว หากคุณต้องการแก้ไข โปรดติดต่อฝ่ายบริการลูกค้าเพื่อยกเลิกการเชื่อมโยง",
    account: "บัญชี",
    financialBill: "ใบเรียกเก็บเงินทางการเงิน",
    totalInvestment: "เงินลงทุนทั้งหมด",
    totalIncome: "รายได้รวม",
    totalProfitAndLoss: "กำไรขาดทุนรวม",
    logout: "ออกจากระบบ",
    bankCard: "บัตรธนาคาร",
    nickname: "ชื่อเล่นของผู้ใช้",
    inputRechargeAmount: "ใส่จำนวนเงินที่เติมเงิน",
    registerSuccessText: "ลงทะเบียนสำเร็จ เข้าสู่ระบบเลย",
    loginSuccessText: "การเข้าสู่ระบบสำเร็จ",
    pleaseEnterAccountNo: "กรุณาป้อนหมายเลขบัญชี",
    pleaseEnterPassword: "กรุณาใส่รหัสผ่าน",
    pleaseConfirmPassword: "กรุณายืนยันรหัสผ่าน",
    pleaseEnterNickname: "โปรดป้อนชื่อเล่น",
    passwordNotMatch: "รหัสผ่านไม่ตรงกัน",
    haveAccount: "มีบัญชีแล้ว",
    rechargeRecord: "บันทึกการเติมเงิน",
    shortTermRoom: "สมบัติระยะสั้น-ห้องจูเนียร์",
    longTermRoom: "สมบัติระยะสั้นยาว-ห้องจูเนียร์",
    lobby: "ห้องโถง",
    distance: "ระยะทาง",
    periodLottery: "กำไรงวด",
    minutes: "นาที",
    seconds: "วินาที",
    lotteryDraw: "ผลหวย",
    period: "ระยะเวลา",
    issueNumber: "หมายเลขปัญหา",
    lotteryResult: "ผลลอตเตอรี",
    topupSuccessText: "ขอบคุณที่ใช้บริการของเรา โปรดติดต่อผู้ดูแลระบบ",
    topupSuccessTitle: "เติมเงินสำเร็จ",
    withdrawSuccessTitle: "ถอนเงินสำเร็จ",
    back: "กลับ",
    status: "สถานะ",
    pending: "รอดำเนินการ",
    success: "สำเร็จ",
    cancel: "ยกเลีก",
    amount: "จำนวนเงิน",
    tNo: "เลขที่",
    date: "วันที่",
    customerName: "ชื่อลูกค้า",
    pleaseConnectYourAccount: "กรุณาผูกบัญชีของคุณ",
    connectAccountSuccess: "ผูกบัญชีสำเร็จ",
    buyPoints: "แต้มชื้อ",
    buy: "ชื้อ",
    insufficientBalance: "ยอดเงินคงเหลือไม่เพียงพอ",
    pleaseEnterBuyAmount: "กรุณาใส่จำนวนเงิน",
    update: "อัปเดต",
    pleaseSelectOptions: "กรุณาเลือกตัวเลือก",
    enterMessage: "ป้อนข้อความ",
    bankPayment: "การชำระเงินด้วยบัตรธนาคาร",
    profile: "โปรไฟล์",
    wrongCredential: "เลขบัญชี หรือ รหัสผ่าน ไม่ถูกต้อง",
    usernameExist: "หมายเลขบัญชีมีแล้ว",
    unSuccess: "ไม่สำเร็จ",
    transactionTimes: "จำนวนธุรกรรม",
    people: "คน",
    minimumPurchase: "การซื้อขั้นต่ำครั้งเดียว",
    enterMinimum: "ใส่จำนวนเงินขั้นต่ำ",
    passwordIncorrect: "รหัสผ่าน ไม่ถูกต้อง",
    minimumBalance: "ยอดเงิน ขั้นต่ำ",
    pleaseEnterAccountName: "กรุณาป้อนชื่อบัญชี",
    pleaseEnterBankName: "กรุณาป้อนชื่อธนาคาร",
    maxAmount: "จำนวนเงินเกีนขั้นสูง",
    marketTrend: "การเปลี่ยนแปลงของตลาด",
    user: "ผู้ใช้",
    rounds: "จำนวนรอบ ",
    tradeCategory: "หมวดหมู่ธุรกรรม",
    singleRound: "ซื้อขายรอบเดียว",
    operation: "ดำเนินงาน",
    todayProfit: "กำไรและขาดทุนวันนี้นี้",
    deposit: "ฝากประจำ",
    productDetail: "รายละเอียดสินค้า",
    onlineService: "บริการออนไลน์",
    depositList: "รายการฝากประจำ",
    depositDate: "วันที่ฝาก",
    profit: "รายได้",
    enterAmount: "ป้อนจำนวนเงิน",
    successText: "สำเร็จ",
    somethingWentWrong: "มีบางอย่างผิดพลาด !",
    setSecurityPassword: "ตั้งรหัสความปลอดภัยใหม่",
    securityPasswordContent:
      "เพิ่มรหัสผ่านเพื่อทำให้ทรัพย์สินของ คุณปลอดภัยยิ่งขึ้น ไม่จำเป็นต้องยืนยันรหัสผ่านเก่าในการตั้งค่าครั้งแรก",
    continue: "ดำเนินต่อ",
    settings: "ตั้งค่า",
    changePassword: "เปลี่ยนรหัสผ่าน",
    updateProfile: "แก้ไขโปรไฟล์ผู้ใช้",
    oldPassword: "รหัสผ่านเก่า",
    newPassword: "รหัสผ่านใหม่",
    confirmNewPassword: "ยืนยันรหัสผ่านใหม่",
    pleaseenterOldPassword: "โปรดป้อนรหัสผ่านเก่า",
    pleaseenterNewPassword: "โปรดป้อนรหัสผ่านใหม่",
    oldPasswordIncorrect: "รหัสผ่านเก่าไม่ถูกต้อง",
    pleaseVerifyIdentity: "โปรด ยืนยันตัวตน",
    identityIsVerified: "ยืนยันตัวตนแล้ว",
    cardName: "ชื่อบัตรประชาชน",
    cardNo: "เลขบัตรประชาชน",
    cardImage: "รูปบัตรประชาชน",
    requiredCardName: "โปรดป้อนชื่อบัตรประชาชน",
    requiredCardNo: "โปรดป้อนเลขบัตรประชาชน",
    requiredCardImage: "โปรดเลือกรูปบัตรประชาชน",
    peningIdentity: "กำลังตรวจสอบยืนยันตัวตน",
    cryptoAddress: "ที่อยู่ คริบโต",
    network: "เครือข่าย",
    crypto: "คริบโต",
    bank: "ธนาคาร",
    betRecord: "บันทึกการเดิมพัน",
    investSuccess: "สำเร็จในการลงทุน",
    investFail: "ล้มเหลวในการลงทุน",
    profitAmount: "กำไร",
  },
};

export default th;
