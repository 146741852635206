export const ROUTE = {
  LANDING: "/",
  HOME: "/home",
  PROFILE: "/profile",
  CONNECT_ACCOUNT: "/profile/connect-account",
  WITHDRAW_RECORD: "/profile/withdraw-record",
  TOPUP_RECORD: "/profile/topup-record",
  FINANCIAL_BILL: "/profile/financial-bill",
  PROFIT_RECORD: "/profile/profit-record",
  TRANSACTION_RECORD: "/profile/transaction-record",
  SETTING: "/profile/setting",
  SECURITY: "/profile/security",
  UPDATE_PASSWORD: "/profile/update-password",
  UPDATE_PROFILE: "/profile/update-profile",
  BET_RECORD: "/profile/bet-record",
  FUND_DETAIL: "/profile/fund-detail",
  VIP: "/profile/vip",
  TOPUP: "/topup",
  TOPUP_DETAIL: "/topup/detail",
  TOPUP_BANK_CARD: "/topup/bank",
  WITHDRAW: "/withdraw",
  WITHDRAW_DETAIL: "/withdraw/detail",
  ASSET: "/asset-room",
  GAME_ROOMS: "/game-rooms",
  SUPPORT: "/support",
  LOGIN: "/auth/login",
  REGISTER: "/auth/register",
  DEPOSIT: "/deposit",
  PRODUCT_DETAIL: "/product-detail",
};
