import { gql } from "@apollo/client";
import { BET_INFO, GAME_INFO } from "../fragments";

export const GET_CURRENT_GAME = gql`
  query getCurrentGame {
    getCurrentGame {
      ...gameInfo
    }
  }
  ${GAME_INFO}
`;

export const GET_LATEST_GAME = gql`
  query getLatestGame {
    getLatestGame {
      ...gameInfo
    }
  }
  ${GAME_INFO}
`;

export const GET_BET_OPTIONS = gql`
  query betOptionList {
    betOptionList {
      id
      name
      engName
      options {
        id
        name
        engName
        profit
      }
    }
  }
`;

export const GET_BET_LIST = gql`
  query getBetList {
    getBetList {
      ...betInfo
    }
  }
  ${BET_INFO}
`;

export const GET_COUNTRY_LIST = gql`
  query getCountryList {
    getCountryList {
      id
      name
      imageUrl
    }
  }
`;

export const GET_ROOM_LIST = gql`
  query roomList {
    roomList {
      id
      name
      engName
      minimumBalance
      isRequirePassword
      isRequireMinimumBalance
    }
  }
`;

export const ENTER_ROOM = gql`
  query enterRoom($room: ID!, $password: String!) {
    enterRoom(room: $room, password: $password) {
      id
    }
  }
`;

export const IS_BUG_OPEN = gql`
  query isBugOpen {
    isBugOpen
  }
`;

export const IS_ROOM_BUG_OPEN = gql`
  query isRoomBugOpen($id: ID!) {
    isRoomBugOpen(id: $id)
  }
`;
