import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Form } from "reactstrap";
import { useMutation, useQuery } from "@apollo/client";

import SubmitButton from "../../components/common/SubmitButton";
import { CONNECT_ACCOUNT } from "../../graphql/mutations/profile";
import BaseLayout from "../../components/layout";
import {
  showErrorToast,
  showSuccessToast,
} from "../../components/common/CustomErrorMessage";
import Header from "../../components/layout/headers/Header";
import CustomRadio from "../../components/forms/CustomRadio";
import { ACCOUNT_TYPE } from "../../constants/app_constants";
import BankForm from "./components/BankForm";
import CryptoForm from "./components/CryptoForm";
import { MEMBER_PROFILE } from "../../graphql/queries/auth";

const ConnectAccount = () => {
  const { t } = useTranslation();
  const [account, setAccount] = useState({
    accountType: ACCOUNT_TYPE.BANK,
    bank: {
      bankName: "",
      accountNo: "",
      accountName: "",
    },
    crypto: {
      address: "",
      network: "",
    },
  });
  const { data: { memberProfile } = {}, refetch: refetchProfile } = useQuery(
    MEMBER_PROFILE,
    {
      onError: (error) => showErrorToast(error.message),
      onCompleted: ({ memberProfile }) => {
        setAccount({
          accountType: memberProfile?.accountType ?? account.accountType,
          bank: memberProfile?.account,
          crypto: memberProfile?.crypto,
        });
      },
    }
  );
  const disabled = memberProfile?.updatedAccount;

  const handleChange = (e) => {
    if (account.accountType == ACCOUNT_TYPE.CRYPTO) {
      setAccount({
        ...account,
        crypto: {
          ...account.crypto,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      setAccount({
        ...account,
        bank: {
          ...account.bank,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const handleChangeRadio = (e) => {
    setAccount({ ...account, accountType: e.target.value });
  };

  const [connectAccount, { loading }] = useMutation(CONNECT_ACCOUNT, {
    variables: {
      input: {
        accountType: account.accountType,
        accountName: account.bank.accountName,
        accountNo: account.bank.accountNo,
        bankName: account.bank.accountName,
        cryptoAddress: account.crypto?.address,
        cryptoNetwork: account.crypto?.network,
      },
    },
    onCompleted: (data) => {
      if (data.connectAccount) {
        showSuccessToast("connectAccountSuccess");
        refetchProfile();
      }
    },
    onError: (error) => {
      showErrorToast(error.message);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await connectAccount();
  };

  const radioItems = [
    {
      label: t("bank"),
      value: ACCOUNT_TYPE.BANK,
    },
    {
      label: t("crypto"),
      value: ACCOUNT_TYPE.CRYPTO,
    },
  ];

  return (
    <BaseLayout>
      <Header title={t("connectBankAccount")} />
      <div className="profile">
        <Container className="p-3">
          <CustomRadio
            items={radioItems}
            name="accountType"
            handleChange={handleChangeRadio}
            defaultValue={account.accountType}
            disabled={disabled}
          />
          <Form onSubmit={handleSubmit}>
            {account.accountType == ACCOUNT_TYPE.CRYPTO ? (
              <CryptoForm
                handleChange={handleChange}
                account={account}
                memberProfile={memberProfile}
                disabled={disabled}
              />
            ) : (
              <BankForm
                handleChange={handleChange}
                account={account}
                memberProfile={memberProfile}
                disabled={disabled}
              />
            )}
            <SubmitButton
              disabled={disabled}
              text={t("confirm")}
              loading={loading}
            />
          </Form>
          <p className="mt-3">{t("bankCardNotice")}</p>
        </Container>
      </div>
    </BaseLayout>
  );
};

export default ConnectAccount;
