import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ERROR_MESSAGE } from "../../constants/error_message";

const CustomErrorMessage = ({ messageKey }) => {
  const { t } = useTranslation();

  switch (messageKey) {
    case ERROR_MESSAGE.WRONG_CREDENTIAL:
      return <span className="text-danger">{t("wrongCredential")}</span>;
    case ERROR_MESSAGE.MEMBER_NOT_FOUND:
      return <span className="text-danger">{t("wrongCredential")}</span>;
    case ERROR_MESSAGE.REQUIRED_NICKNAME:
      return <span className="text-danger">{t("pleaseEnterNickname")}</span>;
    case ERROR_MESSAGE.REQUIRED_USERNAME:
      return <span className="text-danger">{t("pleaseEnterAccountNo")}</span>;
    case ERROR_MESSAGE.REQUIRED_PASSWORD:
      return <span className="text-danger">{t("pleaseEnterPassword")}</span>;
    case ERROR_MESSAGE.REQUIRED_PASSWORD_CONFIRM:
      return <span className="text-danger">{t("pleaseConfirmPassword")}</span>;
    case ERROR_MESSAGE.PASSWORD_NOT_MATCH:
      return <span className="text-danger">{t("passwordNotMatch")}</span>;
    case ERROR_MESSAGE.REQUIRED_AMOUNT:
      return <span className="text-danger">{t("pleaseEnterBuyAmount")}</span>;
    case ERROR_MESSAGE.INSUFFICIENT:
      return <span className="text-danger">{t("insufficientBalance")}</span>;
    case ERROR_MESSAGE.REQUIRED_OPTIONS:
      return <span className="text-danger">{t("pleaseSelectOptions")}</span>;
    case ERROR_MESSAGE.REQUIRED_ACCOUNT_NAME:
      return <span className="text-danger">{t("pleaseEnterAccountName")}</span>;
    case ERROR_MESSAGE.REQUIRED_ACCOUNT_NO:
      return <span className="text-danger">{t("pleaseEnterAccountNo")}</span>;
    case ERROR_MESSAGE.REQUIRED_BANK_NAME:
      return <span className="text-danger">{t("pleaseEnterBankName")}</span>;
    case ERROR_MESSAGE.REQUIRED_WITHDRAW_AMOUNT:
      return (
        <span className="text-danger">{t("pleaseEnterTheWithdrawAmount")}</span>
      );
    case ERROR_MESSAGE.ACCOUNT_NOT_ACONNECTED:
      return (
        <span className="text-danger">{t("pleaseConnectYourAccount")}</span>
      );
    case ERROR_MESSAGE.MAX:
      return <span className="text-danger">{t("maxAmount")}</span>;
    case ERROR_MESSAGE.USERNAME_EXIST:
      return <span className="text-danger">{t("usernameExist")}</span>;
    case ERROR_MESSAGE.OLD_PASSWORD_INCORRECT:
      return <span className="text-danger">{t("oldPasswordIncorrect")}</span>;
    case ERROR_MESSAGE.REQUIRED_CARD_NAME:
      return <span className="text-danger">{t("requiredCardName")}</span>;
    case ERROR_MESSAGE.REQUIRED_CARD_NO:
      return <span className="text-danger">{t("requiredCardNo")}</span>;
    case ERROR_MESSAGE.REQUIRED_CARD_FILE:
      return <span className="text-danger">{t("requiredCardImage")}</span>;
    default:
      return <span className="text-danger">{t("somethingWentWrong")}</span>;
  }
};

const CustomSuccessMessage = ({ messageKey }) => {
  const { t } = useTranslation();

  switch (messageKey) {
    case "registerSuccessText":
      return <span className="text-success">{t("registerSuccessText")}</span>;
    case "loginSuccessText":
      return <span className="text-success">{t("loginSuccessText")}</span>;
    case "withdrawSuccessTitle":
      return <span className="text-success">{t("withdrawSuccessTitle")}</span>;
    default:
      return <span className="text-success">{t("successText")}</span>;
  }
};

export default CustomErrorMessage;

export const showErrorToast = (messageKey) => {
  toast.error(<CustomErrorMessage messageKey={messageKey} />);
};

export const showSuccessToast = (messageKey) => {
  toast.success(<CustomSuccessMessage messageKey={messageKey} />);
};
