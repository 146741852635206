import { useState } from "react";
import { Form } from "reactstrap";
import { Card, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import Footer from "../../components/layout/footers/Footer";
import RowFormGroup from "../../components/forms/RowFormGroup";
import { ROUTE } from "../../constants/app_routes";
import { WITHDRAW_REQUEST } from "../../graphql/mutations/transaction";
import SubmitButton from "../../components/common/SubmitButton";
import BaseLayout from "../../components/layout";
import { formatCurrency } from "../../utils/math";
import { useProfileQuery } from "../../graphql/apollo/query-hooks";
import {
  showErrorToast,
  showSuccessToast,
} from "../../components/common/CustomErrorMessage";
import { currentBranch } from "../../constants/app_constants";

const Withdraw = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [amount, setAmount] = useState(0);
  const { memberProfile } = useProfileQuery();
  const options = [t("bankCard")];

  const [withdrawRequest, { loading }] = useMutation(WITHDRAW_REQUEST, {
    variables: { amount, branch: currentBranch },
    onCompleted: (data) => {
      if (data.withdrawRequest) {
        navigate(ROUTE.WITHDRAW_DETAIL, { state: data.withdrawRequest });
        showSuccessToast("withdrawSuccessTitle");
      }
    },
    onError: (error) => {
      showErrorToast(error.message);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await withdrawRequest();
  };

  return (
    <BaseLayout>
      <div className="withdraw">
        <Card bg="success">
          <Card.Body>
            <h3 className="balance-text text-center text-white mt-2">
              {t("balance")} : {formatCurrency(memberProfile?.balance ?? 0)} THB
            </h3>
          </Card.Body>
        </Card>
        <p className="text-center m-3">
          {t("nickname")}: {memberProfile?.nickname}
        </p>
        <Container>
          <Form onSubmit={handleSubmit}>
            <RowFormGroup
              label={t("receiptType")}
              name="amount"
              type="select"
              options={options}
            />
            <RowFormGroup
              label={t("withdrawAmount")}
              name="amount"
              placeholder={t("withdrawAmount")}
              type="number"
              onChange={(e) => setAmount(parseInt(e.target.value))}
            />
            <RowFormGroup
              label={t("accountNo")}
              placeholder={memberProfile?.account?.accountNo}
              type="text"
              disabled={true}
            />
            <RowFormGroup
              label={t("bankName")}
              placeholder={memberProfile?.account?.bankName}
              type="text"
              disabled={true}
            />
            <RowFormGroup
              label={t("payee")}
              type="text"
              placeholder={memberProfile?.username}
              disabled={true}
            />
            <SubmitButton text={t("confirm")} loading={loading} />
          </Form>
        </Container>
        <Footer />
      </div>
    </BaseLayout>
  );
};

export default Withdraw;
