const en = {
  translation: {
    switchLanguage: "Switch Language",
    login: "Login",
    register: "Register",
    pleaseEnterAccountNo: "Please enter the account number",
    pleaseEnterPassword: "Please enter the password",
    pleaseConfirmPassword: "Please confirm password",
    pleaseEnterNickname: "Please enter a nickname",
    passwordNotMatch: "Password not match",
    registerNow: "Register Now",
    confirm: "Confirm",
    alreadyhaveAccount: "Already Have Account?",
    shortTermRoom: "short-term treasure-junior room",
    longTermRoom: "long-term treasure-junior room",
    home: "Home",
    myProfile: "My Profile",
    recharge: "Recharge",
    withdraw: "Withdraw",
    customerService: "Customer Service",
    balance: "Balance",
    ID: "ID",
    shortTerm: "Short Term",
    small: "Small",
    big: "Big",
    Short: "Short",
    long: "Long",
    high: "high",
    low: "Low",
    double: "double",
    single: "single",
    connectBankAccount: "Connect bank account",
    bankAccount: "Bank account",
    accountNo: "accountNo",
    accountName: "account name",
    recieverName: "Reciever name",
    rechartRecord: "Rechart record",
    withdrawRecord: "Withdraw record",
    profitRecord: "Profit record",
    memberLevel: "Member level",
    totalAssets: "Total assets",
    onlineNumber: "Online number",
    memberID: "member ID",
    goldMember: "Gold Member",
    vipMember: "VIP Member",
    receiptType: "Receipt Type",
    bankName: "Bank name",
    viewCardRecord: "View card record",
    supportText: "Contact online customer service",
    withdrawAmount: "Withdraw amount",
    bankCardNumber: "Bank card number",
    payee: "payee",
    USDTAddress: "USDT address",
    topupNotice:
      "Please contact online customer service for proceed your transaction",
    topupBankNotice:
      "Please contact online customer service for the latest account charges!",
    warmRemindertitle: "Warm reminder",
    warmRemindertext: "รับทราบการชำระเงินด้วยรหัส QR แล้วกรอกจำนวนเงินที่จะส่ง",
    remark: "Remark",
    pleaseEnterTheRechargeAmount: "Please enter the recharge amount",
    pleaseEnterTheWithdrawAmount: "Please enter the withdraw amount",
    pleaseEnterANote: "Please enter a note",
    rechargeAmount: "Recharge amount",
    bankCardNotice:
      "When you have connect your account, please contact customer service if you want to cancel",
    account: "Account",
    financialBill: "Financial Bill",
    totalInvestment: "Total investment",
    totalIncome: "Total Income",
    totalProfitAndLoss: "Total profit and loss",
    logout: "Logout",
    bankCard: "Bank card",
    nickname: "Nickname",
    inputRechargeAmount: "Input recharge amount",
    registerSuccessText: "Register success! Let's login",
    loginSuccessText: "Login success",
    haveAccount: "Have account?",
    rechargeRecord: "Recharge record",
    lobby: "Lobby",
    distance: "distance",
    periodLottery: "profit lottery",
    minutes: "minutes",
    seconds: "seconds",
    lotteryDraw: "lottery draw",
    period: "period",
    issueNumber: "issue number",
    lotteryResult: "lottery result",
    topupSuccessTitle: "Recharge success",
    withdrawSuccessTitle: "Withdraw success",
    back: "Back",
    status: "Status",
    pending: "Pending",
    success: "Success",
    cancel: "Cancel",
    amount: "Amount",
    tNo: "No",
    date: "Date",
    customerName: "Customer Name",
    pleaseConnectYourAccount: "Please Connect Your Account",
    connectAccountSuccess: "Connect account success",
    buyPoints: "Buy points",
    buy: "Buy",
    insufficientBalance: "Insufficient balance",
    pleaseEnterBuyAmount: "Please enter buy amount",
    update: "Update",
    pleaseSelectOptions: "Please select options",
    enterMessage: "Enter message",
    bankPayment: "Bank card payment",
    profile: "Profile",
    wrongCredential: "AccountNo or password is incorrect",
    usernameExist: "Account number is already exist",
    unSuccess: "Unsuccess",
    transactionTimes: "Number of transactions",
    people: "people",
    minimumPurchase: "Single minimum purchase",
    enterMinimum: "Enter the minimum amount",
    passwordIncorrect: "Password is incorrect",
    minimumBalance: "Minimum balance",
    pleaseEnterAccountName: "Please enter a account name",
    pleaseEnterBankName: "Please enter a bank name",
    maxAmount: "You enter the maximum amount",
    marketTrend: "Market Trends",
    user: "User",
    rounds: "Round",
    tradeCategory: "Trade Category",
    singleRound: "Single Round",
    operation: "Operation",
    todayProfit: "Profit today",
    deposit: "Deposit",
    productDetail: "Product Introduction",
    onlineService: "Online Service",
    depositList: "Deposit List",
    depositDate: "Deposit Date",
    profit: "Profit",
    enterAmount: "Enter amount",
    successText: "Success",
    somethingWentWrong: "Something went wrong !",
    setSecurityPassword: "Set Security Password",
    securityPasswordContent:
      "Add a password to make your property you are safer There is no need to confirm the old password at first time setup.",
    continue: "Continue",
    settings: "Settings",
    changePassword: "Update password",
    updateProfile: "Update Profile",
    oldPassword: "Old password",
    newPassword: "New password",
    confirmNewPassword: "Confirm new password",
    pleaseenterOldPassword: "Please enter old password",
    pleaseenterNewPassword: "Please enter new password",
    oldPasswordIncorrect: "Old Password Incorrect",
    pleaseVerifyIdentity: "Please Verify Identity",
    identityIsVerified: "Identity is verified",
    cardName: "ID card name",
    cardNo: "ID card No",
    cardImage: "ID card image",
    requiredCardName: "Please enter ID card name",
    requiredCardNo: "Please enter ID card name",
    requiredCardImage: "Please take ID card image",
    peningIdentity: "Pending verifying identity",
    cryptoAddress: "Crypto Address",
    network: "Network",
    crypto: "Crypto",
    bank: "Bank",
    betRecord: "Bet Record",
    investSuccess: "Successful investment",
    investFail: "Investment failed",
    profitAmount: "profit",
  },
};

export default en;
