import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { ROUTE } from "../constants/app_routes";
import Landing from "../pages/landing";
import Home from "../pages/home";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import Topup from "../pages/topup";
import Withdraw from "../pages/withdraw";
import Support from "../pages/support";
import Profile from "../pages/profile";
import TopupDetail from "../pages/topup/TopupDetail";
import ConnectAccount from "../pages/profile/ConnectAccount";
import WithdrawRecord from "../pages/profile/WithdrawRecord";
import TopupRecord from "../pages/profile/TopupRecord";
import FinancialBill from "../pages/profile/FinancialBill";
import ProfitRecord from "../pages/profile/ProfitRecord";
import Asset from "../pages/asset";
import BankCardTopup from "../pages/topup/BankCardTopup";
import WithdrawDetail from "../pages/withdraw/WithdrawDetail";
import GameRooms from "../pages/asset/GameRooms";
import Deposit from "../pages/deposit";
import ProductDetail from "../pages/profile/ProductDetail";
import TransactionRecord from "../pages/profile/TransactionRecord";
import BetRecord from "../pages/profile/BetRecord";
import SecurityPassword from "../pages/profile/SecurityPassword";
import Settings from "../pages/profile/Settings";
import UpdatePassword from "../pages/profile/UpdatePassword";
import UpdateProfile from "../pages/profile/UpdateProfile";

const AppRoutes = () => {
  const routes = createBrowserRouter([
    { path: ROUTE.LANDING, element: <Landing /> },
    { path: ROUTE.HOME, element: <Home /> },
    { path: ROUTE.TOPUP, element: <Topup /> },
    { path: ROUTE.TOPUP_DETAIL, element: <TopupDetail /> },
    { path: ROUTE.TOPUP_BANK_CARD, element: <BankCardTopup /> },
    { path: ROUTE.WITHDRAW, element: <Withdraw /> },
    { path: ROUTE.WITHDRAW_DETAIL, element: <WithdrawDetail /> },
    { path: ROUTE.SUPPORT, element: <Support /> },
    { path: ROUTE.PROFILE, element: <Profile /> },
    { path: ROUTE.CONNECT_ACCOUNT, element: <ConnectAccount /> },
    { path: ROUTE.WITHDRAW_RECORD, element: <WithdrawRecord /> },
    { path: ROUTE.TOPUP_RECORD, element: <TopupRecord /> },
    { path: ROUTE.FINANCIAL_BILL, element: <FinancialBill /> },
    { path: ROUTE.PROFIT_RECORD, element: <ProfitRecord /> },
    { path: ROUTE.ASSET, element: <Asset /> },
    { path: ROUTE.GAME_ROOMS, element: <GameRooms /> },
    { path: ROUTE.LOGIN, element: <Login /> },
    { path: ROUTE.REGISTER, element: <Register /> },
    { path: ROUTE.DEPOSIT, element: <Deposit /> },
    { path: ROUTE.PRODUCT_DETAIL, element: <ProductDetail /> },
    { path: ROUTE.TRANSACTION_RECORD, element: <TransactionRecord /> },
    { path: ROUTE.BET_RECORD, element: <BetRecord /> },
    { path: ROUTE.SECURITY, element: <SecurityPassword /> },
    { path: ROUTE.SETTING, element: <Settings /> },
    { path: ROUTE.UPDATE_PASSWORD, element: <UpdatePassword /> },
    { path: ROUTE.UPDATE_PROFILE, element: <UpdateProfile /> },
  ]);

  return <RouterProvider router={routes} />;
};

export default AppRoutes;
