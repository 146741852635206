import { gql } from "@apollo/client";
import { MEMBER_INFO } from "../fragments";

export const CONNECT_ACCOUNT = gql`
  mutation connectAccount($input: AccountInput!) {
    connectAccount(input: $input) {
      ...memberInfo
    }
  }
  ${MEMBER_INFO}
`;

export const UPDATE_PROFILE_IMAGE = gql`
  mutation updateProfileImage($imageUrl: String!) {
    updateProfileImage(imageUrl: $imageUrl) {
      ...memberInfo
    }
  }
  ${MEMBER_INFO}
`;

export const UPDATE_SECURITY_PASSWORD = gql`
  mutation setSecurityPassword($password: String!) {
    setSecurityPassword(password: $password) {
      id
    }
  }
`;

export const UPDATE_LOGIN_PASSWORD = gql`
  mutation updatePassword($input: PasswordInput!) {
    updatePassword(input: $input) {
      id
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
    }
  }
`;

export const VERIFY_IDENTITY = gql`
  mutation verifyIdentity($input: IdentityInput!) {
    verifyIdentity(input: $input) {
      id
    }
  }
`;
