import {
  FaChevronDown,
  FaRegEdit,
  FaSave,
  FaChevronRight,
  FaRegIdCard,
  FaLock,
  FaUserCog,
} from "react-icons/fa";
import {
  AiFillDelete,
  AiOutlineFieldTime,
  AiTwotoneTrophy,
  AiOutlineLogout,
  AiFillCamera,
  AiFillGift,
} from "react-icons/ai";
import { IoMdSend, IoMdSettings } from "react-icons/io";
import {
  BsImage,
  BsCheckCircle,
  BsFillQuestionCircleFill,
  BsShieldPlus,
} from "react-icons/bs";
import { ImUsers } from "react-icons/im";
import { SiAdblock } from "react-icons/si";
import { RxCross2 } from "react-icons/rx";
import { GrStatusGoodSmall } from "react-icons/gr";
import {
  FcProcess,
  FcAddDatabase,
  FcDeleteDatabase,
  FcCustomerSupport,
} from "react-icons/fc";
import {
  MdCancel,
  MdSwapHoriz,
  MdOutlineAccountBalanceWallet,
  MdKeyboardHide,
  MdPassword,
  MdOutlineVerified,
} from "react-icons/md";
import { IoIosAddCircle } from "react-icons/io";
import { BiTimeFive, BiDetail } from "react-icons/bi";
import { TbEqual } from "react-icons/tb";
import { HiOutlinePlusSm } from "react-icons/hi";
import { GiReceiveMoney } from "react-icons/gi";
import { IoChevronBackSharp } from "react-icons/io5";
import { FiMonitor, FiDelete } from "react-icons/fi";
import { RiVipLine } from "react-icons/ri";
import { CiViewList } from "react-icons/ci";
import { PiHandWithdraw } from "react-icons/pi";
import { FcCompactCamera } from "react-icons/fc";
import { LuLanguages } from "react-icons/lu";

const UsersIcon = ImUsers;
const DurationIcon = AiOutlineFieldTime;
const TimeIcon = BiTimeFive;
const EqualIcon = TbEqual;
const PlusIcon = HiOutlinePlusSm;
const LevelIcon = AiTwotoneTrophy;
const LogoutIcon = AiOutlineLogout;
const CameraIcon = AiFillCamera;
const DatabaseIcon = FcAddDatabase;
const DeleteDatabaseIcon = FcDeleteDatabase;
const ChevronRightIcon = FaChevronRight;
const CardIcon = FaRegIdCard;
const MoneyIcon = GiReceiveMoney;
const ChevronBackIcon = IoChevronBackSharp;
const ChevronIcon = FaChevronDown;
const TrashIcon = AiFillDelete;
const SendIcon = IoMdSend;
const EditIcon = FaRegEdit;
const ImageIcon = BsImage;
const UnBlockIcon = SiAdblock;
const CrossIcon = RxCross2;
const CheckCircleIcon = BsCheckCircle;
const StatusIcon = GrStatusGoodSmall;
const ProcessIcon = FcProcess;
const CancelIcon = MdCancel;
const SaveIcon = FaSave;
const AddCircleIcon = IoIosAddCircle;
const GiftIcon = AiFillGift;
const DetailIcon = BiDetail;
const CustomerSupportIcon = FcCustomerSupport;
const MonitorIcon = FiMonitor;
const QuestionIcon = BsFillQuestionCircleFill;
const VipIcon = RiVipLine;
const TransactionIcon = MdSwapHoriz;
const SettingIcon = IoMdSettings;
const SecurityIcon = BsShieldPlus;
const ListIcon = CiViewList;
const WalletIcon = MdOutlineAccountBalanceWallet;
const WithdrawIcon = PiHandWithdraw;
const CameraColorIcon = FcCompactCamera;
const DeleteBackIcon = FiDelete;
const KeyboardHideIcon = MdKeyboardHide;
const LockIcon = FaLock;
const UserCogIcon = FaUserCog;
const LanguageIcon = LuLanguages;
const NewPasswordIcon = MdPassword;
const VerifyIcon = MdOutlineVerified;

export {
  UsersIcon,
  DurationIcon,
  TimeIcon,
  EqualIcon,
  PlusIcon,
  LevelIcon,
  LogoutIcon,
  CameraIcon,
  DatabaseIcon,
  DeleteDatabaseIcon,
  ChevronRightIcon,
  CardIcon,
  MoneyIcon,
  ChevronBackIcon,
  ChevronIcon,
  TrashIcon,
  SendIcon,
  EditIcon,
  ImageIcon,
  UnBlockIcon,
  CrossIcon,
  CheckCircleIcon,
  StatusIcon,
  ProcessIcon,
  CancelIcon,
  AddCircleIcon,
  SaveIcon,
  GiftIcon,
  DetailIcon,
  CustomerSupportIcon,
  MonitorIcon,
  QuestionIcon,
  VipIcon,
  TransactionIcon,
  SettingIcon,
  SecurityIcon,
  ListIcon,
  WalletIcon,
  WithdrawIcon,
  CameraColorIcon,
  DeleteBackIcon,
  KeyboardHideIcon,
  LockIcon,
  UserCogIcon,
  LanguageIcon,
  NewPasswordIcon,
  VerifyIcon,
};
